@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 245, 247, 250;
  --background-end-rgb: 255, 255, 255;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
    to bottom,
    rgb(var(--background-start-rgb)),
    rgb(var(--background-end-rgb))
  );
  min-height: 100vh;
}

/* Leaflet map styles */
.leaflet-container {
  width: 100%;
  height: 100%;
}

.station-marker {
  background: none;
  border: none;
}

/* Hide leaflet attribution for clean UI */
.leaflet-control-attribution {
  display: none;
}

/* Prevent blue highlight on mobile tap */
@media (max-width: 768px) {
  * {
    -webkit-tap-highlight-color: transparent;
  }
}

/* Loading animations */
@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}